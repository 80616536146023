import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";
import {t} from "../utils/filters";

// Async load the vue module
Promise.all([
    import(/* webpackChunkName: "vue" */ 'vue'),
    import(/* webpackChunkName: "vueevents" */ 'vue-events'),
    import(/* webpackChunkName: "gallerymodalwrapper" */ '../../vue/GalleryModal/GalleryModalWrapper.vue')
]).then(([{default: Vue}, {default: VueEvents}, {default: GalleryModalWrapper}]) => {
    Vue.use(VueEvents);
    Vue.filter('t', t);
    const app = new Vue({
        components: {
            GalleryModalWrapper: GalleryModalWrapper,
        },
        data: {}
    });

    app.$events.$on('ready', () => {
        if (window.launchGallery) {
            app.$events.$emit('loadProject', window.launchGallery);
            return;
        }
        const key = '#view-project:';
        const hash = window.location.hash;
        if (!hash.startsWith(key)) {
            return;
        }
        const slug = hash.substring(key.length);

        app.$events.$emit('loadProject', slug);
    });

    app.$mount('#gallery-modal');

    document.addEventListener('click',function(e) {
        if (e.target.dataset && e.target.dataset['expandGallery']) {
            e.preventDefault();
            app.$events.$emit('loadProject', e.target.dataset.expandGallery);
        }
    });

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
